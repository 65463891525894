import { useContext } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import Chat from 'components/ChatPage/ChatPage'
import Analysis from 'components/DashboardPage/Analysis/Analysis'
import AssetsComparator from 'components/DashboardPage/AssetsComparator/AssetsComparator'
import OperationsAssetsComparator from 'components/DashboardPage/AssetsComparator/OperationsAssetsComparator'
import OperationsRebalanceComparator from 'components/DashboardPage/AssetsComparator/OperationsRebalanceComparator'
import Documents from 'components/DashboardPage/Documents/Documents'
import Movements from 'components/DashboardPage/Movements/Movements'
import CashHoldings from 'components/DashboardPage/Operations/CashHoldings'
import Clients from 'components/DashboardPage/Operations/Clients'
import ManagementCommands from 'components/DashboardPage/Operations/Commands'
import Datasources from 'components/DashboardPage/Operations/Datasources'
import Dividends from 'components/DashboardPage/Operations/Dividends'
import Holdings from 'components/DashboardPage/Operations/Holdings'
import ModelsCRUDTables from 'components/DashboardPage/Operations/ModelsCRUDTables.jsx'
import PortfolioSort from 'components/DashboardPage/Operations/PortfolioSort'
import OPReports from 'components/DashboardPage/Operations/Reports'
import Splits from 'components/DashboardPage/Operations/Splits'
import Transactions from 'components/DashboardPage/Operations/Transactions'
import Portfolio from 'components/DashboardPage/Portfolio/Portfolio'
import Reports from 'components/DashboardPage/Reports/Reports'
import Summary from 'components/DashboardPage/Summary/Summary'
import Hijack from 'components/Hijack/Hijack'
import PlaidIntegration from 'components/Plaid/plaidintegration'
import { FlagsContext } from 'context/flagsContext'
import PrivateRoute from 'utils/Routing/PrivateRoute'

export default function HeaderRouter() {
  const userFlags = useContext(FlagsContext)

  return (
    <Switch>
      <PrivateRoute path="/dashboard/summary" exact component={Summary} />
      <PrivateRoute path="/dashboard/analysis" exact component={Analysis} />
      <PrivateRoute path="/dashboard/portfolio" exact component={Portfolio} />
      <PrivateRoute
        path="/dashboard/assets"
        exact
        component={AssetsComparator}
      />
      <PrivateRoute path="/dashboard/movements" exact component={Movements} />
      <PrivateRoute path="/dashboard/documents" exact component={Documents} />
      <PrivateRoute path="/dashboard/reports" exact component={Reports} />

      {userFlags?.flags.CHATBOT_FLAG && (
        <PrivateRoute path="/dashboard/chats" exact component={Chat} />
      )}

      <PrivateRoute path="/dashboard/admin-hijack" exact component={Hijack} />
      <PrivateRoute
        path="/dashboard/operations/holdings"
        exact
        component={Holdings}
      />
      <PrivateRoute
        path="/dashboard/operations"
        exact
        component={Datasources}
      />
      <PrivateRoute
        path="/dashboard/operations/dividends"
        exact
        component={Dividends}
      />
      <PrivateRoute
        path="/dashboard/operations/splits"
        exact
        component={Splits}
      />
      <PrivateRoute
        path="/dashboard/operations/reports"
        exact
        component={OPReports}
      />
      <PrivateRoute
        path="/dashboard/operations/clients"
        exact
        component={Clients}
      />
      <PrivateRoute
        path="/dashboard/operations/cash-holdings"
        exact
        component={CashHoldings}
      />
      <PrivateRoute
        path="/dashboard/operations/models-CRUD-tables"
        exact
        component={ModelsCRUDTables}
      />
      <PrivateRoute
        path="/dashboard/operations/portfolio-sort"
        exact
        component={PortfolioSort}
      />
      <PrivateRoute
        path="/dashboard/plaid"
        exact
        component={PlaidIntegration}
      />
      <PrivateRoute
        path="/dashboard/operations/transactions"
        exact
        component={Transactions}
      />
      <PrivateRoute
        path="/dashboard/operations/commands"
        exact
        component={ManagementCommands}
      />
      <PrivateRoute
        path="/dashboard/operations/comparator"
        exact
        component={OperationsAssetsComparator}
      />
      <PrivateRoute
        path="/dashboard/operations/rebalance"
        exact
        component={OperationsRebalanceComparator}
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}
