import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import baseStyles from 'utils/UI/BaseStyles'

const percentageFormatter = (value) => {
  if (!value) return '-'

  const percentage = ((value - 1) * 100).toFixed(1)
  return percentage === '-0.0' ? '0.0%' : `${percentage}%`
}

export default function ReturnsTable({ returnsData }) {
  const classes = baseStyles()

  if (!returnsData) {
    return <div>No hay datos para mostrar</div>
  }

  const { cagr, vol, ...returns } = returnsData

  const getColorClass = (value) => {
    if (!value || value === '-') return ''
    const numericValue = parseFloat(value.replace('%', ''))

    return numericValue >= 0 ? classes.successColor : classes.errorColor
  }

  const order = [
    'Custom',
    'CAGR',
    'Vol',
    '1M',
    '3M',
    'YTD',
    '2023',
    '2022',
    '2021',
    '1Y',
    '3Y',
    '5Y'
  ]

  const indicators = {
    CAGR: cagr ? `${(parseFloat(cagr) * 100).toFixed(1)}%` : '-',
    Vol: vol ? `${(parseFloat(vol) * 100).toFixed(1)}%` : '-',
    ...Object.keys(returns).reduce((acc, key) => {
      acc[key] = returns[key] ? percentageFormatter(returns[key]) : '-'
      return acc
    }, {})
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}></TableCell>
            {order.map((indicator) => (
              <TableCell
                key={indicator}
                style={{ fontWeight: 'bold' }}
                align="center"
              >
                {indicator}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Portafolio</TableCell>
            {order.map((indicator) => (
              <TableCell
                key={indicator}
                align="center"
                className={getColorClass(indicators[indicator])}
              >
                {indicators[indicator]}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
