import clsx from 'clsx'
import { format } from 'date-fns'
import { useContext, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'

import { Grid, Hidden, useMediaQuery } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'

import HeaderContext from 'context/headerContext'
import useResponsive from 'hooks/useResponsive'
import { bigNumberFormatter } from 'utils/formatters/currencyFormatters'
import { percentageFormatter } from 'utils/formatters/percentageFormatters'
import baseStyles from 'utils/UI/BaseStyles'
import CustomModeButtonGroup from 'utils/UI/Buttons/CustomModeButtonGroup'
import Search from 'utils/UI/Inputs/Search'
import Loading from 'utils/UI/Loading'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import { positionsSummaryRequest } from 'axios/requests/summary'
import { errorNotification } from 'utils/UI/Notifications/Notifications'
import DefaultTable from 'utils/UI/Tables/DefaultTable'

const FirstLinearProgress = withStyles((theme) => ({
  root: {
    margin: 3,
    width: '100%',
    minWidth: 180,
    borderRadius: 10,
    height: 10
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.light
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main
  }
}))(LinearProgress)

const SecondLinearProgress = withStyles((theme) => ({
  root: {
    margin: 3,
    width: '100%',
    minWidth: 180,
    borderRadius: 10,
    height: 10
  },
  colorPrimary: {
    backgroundColor: theme.palette.secondary.light
  },
  bar: {
    borderRadius: 3,
    backgroundColor: theme.palette.info.main
  }
}))(LinearProgress)

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableContainer-root': {
      minWidth: 400,
      '& .MuiTable-root': {
        tableLayout: 'auto'
      }
    }
  },
  assetClassName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0
  },
  graphSelectorsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    width: '100%',
    marginBottom: theme.spacing(0)
  },
  graphDataType: {
    height: 'auto',
    width: '100%'
  },
  progressBarCol: {
    width: 500,
    [theme.breakpoints.up('lg')]: {
      width: '100%'
    }
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& span': {
      width: 32,
      lineHeight: 1
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  hideSortIcon: {
    '& .MuiTableSortLabel-icon': {
      display: 'none',
      visibility: 'hidden'
    }
  }
}))

export default function PositionsSummary() {
  const { t } = useTranslation(['dashboard', 'asset_classes'])
  const classes = useStyles()
  const baseClasses = baseStyles()
  const [search, setSearch] = useState('')
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency, currentPortfolio } = headerState
  const [aggregate, setMode] = useState('root-class')
  const [tableData, setTableData] = useState(null)
  const { isMobile } = useResponsive()
  const [dataObjective, setDataObjective] = useState(false)
  const matchesMobile = useMediaQuery('(max-width:465px)')
  const [loading, setLoading] = useState(true)
  const numColumns = dataObjective ? 6 : 5
  const maxColumnWidth = Math.floor(350 / numColumns)

  const defaultModeList = useMemo(
    () => [
      { key: 'root-class', label: t('Clase de Activo') },
      { key: 'macro_asset', label: t('Macro Activo') },
      { key: 'currency', label: t('Moneda') },
      { key: 'institution', label: t('Institución') },
      { key: 'asset', label: t('Activo') }
    ],
    [t]
  )

  useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      try {
        setLoading(true)
        const result = await positionsSummaryRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          currency,
          portfolio_id: currentPortfolio.id,
          aggregate
        })
        return result.data
      } catch (err) {
        errorNotification('generalError')
        setLoading(false)
      }
    }

    if (mounted && currentPortfolio && currency) {
      fetchData().then((data) => {
        if (mounted && data?.positionsSummaryData) {
          setTableData(data.positionsSummaryData)
          setLoading(false)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [currency, currentPortfolio, aggregate, startDate, endDate])

  useEffect(() => {
    if (!tableData) return
    bindTooltips()
  }, [tableData])

  const data = useMemo(() => {
    if (search && aggregate === 'asset' && tableData) {
      return tableData.filter((x) => {
        return x.name.toLowerCase().includes(search.toLowerCase())
      })
    } else {
      return tableData || []
    }
  }, [tableData, search, aggregate])

  useEffect(() => {
    if (tableData) {
      const totalTargetWeight = tableData.reduce(
        (acc, row) => acc + parseFloat(row.target_weight),
        0
      )
      setDataObjective(totalTargetWeight > 0)
    }
  }, [tableData])

  const bindTooltips = () => {
    setTimeout(() => {
      const assetsElements = document.getElementsByClassName('assetName')
      var fun = function () {
        if (this.offsetWidth < this.scrollWidth) {
          // eslint-disable-next-line react/no-deprecated
          ReactDOM.render(
            <Tooltip title={this.dataset.asset}>
              <span>{this.dataset.asset}</span>
            </Tooltip>,
            this
          )
        } else {
          // eslint-disable-next-line react/no-deprecated
          ReactDOM.render(this.dataset.asset, this)
        }
      }
      for (var i = 0; i < assetsElements.length; i++) {
        assetsElements[i].addEventListener('mouseover', fun, false)
      }
    }, 500)
  }

  const findMaxNum = (position, target) => {
    return Math.max(...[...Object.values(position), ...Object.values(target)])
  }

  const adjust = (toAdjust, maxNum) => {
    return Object.entries(toAdjust).reduce((acc, [key, value]) => {
      acc[key] = (value / maxNum) * 100
      return acc
    }, {})
  }

  const adjustData = useMemo(() => {
    if (!tableData) return {}
    let positionValues = {}
    let targetValues = {}
    tableData.forEach((row) => {
      positionValues[row.name] = row.weight
      targetValues[row.name] = row.target_weight
    })
    const maxNum = findMaxNum(positionValues, targetValues)

    positionValues = adjust(positionValues, maxNum)
    targetValues = adjust(targetValues, maxNum)

    return {
      positionValues,
      targetValues
    }
  }, [tableData])

  const columns = useMemo(() => {
    const firstColumnLabel =
      aggregate === 'root-class' || aggregate === 'macro_asset'
        ? t('Clase de Activo')
        : defaultModeList.find((item) => item.key === aggregate)?.label || ''

    const cols = [
      {
        label: firstColumnLabel,
        id: 'name',
        align: 'left',
        render: (row) => {
          const text = t(row.name, { ns: 'asset_classes' })
          return row.name.length > maxColumnWidth ? (
            <Tooltip title={text}>
              <span>{text}</span>
            </Tooltip>
          ) : (
            <p
              data-asset={text}
              className={clsx('assetName', classes.assetClassName)}
            >
              {text}
            </p>
          )
        }
      },
      {
        label: t('Posición'),
        id: 'weight',
        align: 'right',
        render: (row) => percentageFormatter(row.weight)
      },
      {
        label: t('Valor'),
        id: 'value',
        align: 'right',
        render: (row) => (
          <Tooltip
            title={
              aggregate === 'asset' ? (
                <div>
                  valor cuota: {row.valor_cuota} <br /> numero de cuotas:{' '}
                  {row.num_cuotas}
                </div>
              ) : (
                ''
              )
            }
            arrow
            placement="right"
          >
            <div>{bigNumberFormatter(row.value)}</div>
          </Tooltip>
        )
      },
      {
        label: t('Retorno'),
        id: 'return_value',
        align: 'center',
        render: (row) => (
          <span
            className={clsx({
              [baseClasses.errorColor]: row.return_value < 0,
              [baseClasses.successColor]: row.return_value > 0
            })}
          >
            {percentageFormatter(row.return_value * 100)}
          </span>
        )
      },
      {
        label: t('Actual y Objetivo'),
        id: 'weight-and-target_weight',
        sorteable: false,
        minWidth: '30%',
        align: 'center',
        render: (row) => (
          <div className={classes.barContainer}>
            <Tooltip title={`${percentageFormatter(row.weight)}%`} arrow>
              <FirstLinearProgress
                variant="determinate"
                value={adjustData.positionValues[row.name]}
              />
            </Tooltip>
            {row.target_weight !== 0 && (
              <Tooltip title={`${row.target_weight}%`} arrow>
                <SecondLinearProgress
                  variant="determinate"
                  value={adjustData.targetValues[row.name]}
                />
              </Tooltip>
            )}
          </div>
        )
      }
    ]

    if (dataObjective) {
      cols.splice(1, 0, {
        label: t('Objetivo'),
        id: 'target_weight',
        align: 'right',
        render: (row) => percentageFormatter(row.target_weight)
      })
    }

    return cols
  }, [t, dataObjective, aggregate, defaultModeList, adjustData, classes])

  const renderModeButtonGroup = (
    <CustomModeButtonGroup
      modeList={defaultModeList}
      currentValue={aggregate}
      onClickHandler={setMode}
    />
  )

  const renderModeButtonGroupMobile = (
    <>
      {matchesMobile ? (
        <Grid container>
          <CustomModeButtonGroup
            modeList={defaultModeList}
            currentValue={aggregate}
            onClickHandler={setMode}
          />
          {aggregate === 'asset' ? (
            <Search
              placeholder="Buscar..."
              width={160}
              onChange={setSearch}
              value={search}
            />
          ) : null}
        </Grid>
      ) : (
        <>
          {aggregate === 'asset' ? (
            <Search
              placeholder="Buscar..."
              width={160}
              onChange={setSearch}
              value={search}
            />
          ) : null}
          <CustomModeButtonGroup
            modeList={defaultModeList}
            currentValue={aggregate}
            onClickHandler={setMode}
          />
        </>
      )}
    </>
  )

  return (
    <CardWrapper
      title={t('Posiciones')}
      action={isMobile ? renderModeButtonGroupMobile : null}
      shadow={false}
    >
      <Hidden smDown>
        <div className={classes.graphSelectorsContainer}>
          <div className={classes.graphDataType}>{renderModeButtonGroup}</div>
          {aggregate === 'asset' ? (
            <Search
              placeholder="Buscar..."
              width={200}
              onChange={setSearch}
              value={search}
            />
          ) : null}
        </div>
      </Hidden>
      {loading || !tableData ? (
        <Loading />
      ) : (
        <DefaultTable
          colsData={columns}
          rowsData={data}
          maxHeight={false}
          cellPadding={20}
        />
      )}
    </CardWrapper>
  )
}
