import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { format, parseISO } from 'date-fns'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import ReturnsTable from './RebalanceReturnsTable'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AddBoxIcon from '@material-ui/icons/AddBox'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import GetAppIcon from '@material-ui/icons/GetApp'
import SaveIcon from '@material-ui/icons/Save'
import Autocomplete from '@material-ui/lab/Autocomplete'

import HeaderContext from 'context/headerContext'
import Loading from 'utils/UI/Loading'
import { errorNotification } from 'utils/UI/Notifications/Notifications'
import { COLORS_GRAPH_ASSETS, getTextColor } from 'utils/UI/Theme'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'

import {
  createRebalancePortfolioRequest,
  deleteRebalancePortfolioRequest,
  downloadRebalancePortfolioRequest,
  portfolioAssetsSearchRequest,
  rebalancePortfolioSearchRequest,
  rebalanceReturnsRequest,
  updateRebalancePortfolioRequest
} from 'axios/requests/assetsComparator'

const useStyles = makeStyles((theme) => ({
  assetsSelectorContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  assetsSelector: {},
  saveButton: {
    marginLeft: 'auto'
  },
  deleteIcon: {
    color: '#EAEAEA'
  }
}))

export default function OperationsRebalanceAssetsSeriesReturns() {
  const classes = useStyles()
  const theme = useTheme()
  const [openSaveDialog, setOpenSaveDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openRebalanceDialog, setOpenRebalanceDialog] = useState(false)
  const [savedListEdit, setSavedListEdit] = useState({ uuid: null, text: '' })
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currency } = headerState
  const [loading, setLoading] = useState(true)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [tickers, setTickers] = useState([])
  const [selectedTickersNamesDict, setSelectedTickersNamesDict] = useState({})
  const [tickersWeightsDict, setTickersWeightsDict] = useState({})
  const [weightsError, setWeightsError] = useState('')
  const [rebalanceFrequency, setRebalanceFrequency] = useState('No Rebalance')
  const [savedList, setSavedList] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [optionsTickers, setOptionsTickers] = useState([])
  const intervalRef = useRef()
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [loadingSavedList, setLoadingSavedList] = useState(false)
  const [showInfoTable, setShowInfoTable] = useState(false)
  const [returnsData, setReturnsData] = useState(null)

  const [open, setOpen] = useState(false)

  const [chartOptions, setChartOptions] = useState({
    credits: false,
    plotOptions: {
      line: {
        marker: {
          enabled: false
        },
        events: {
          legendItemClick: function () {
            return false
          }
        }
      }
    },
    chart: {
      zoomType: 'x',
      style: {
        fontFamily: 'InconsolataRegular'
      }
    },
    title: {
      text: ''
    },
    xAxis: {
      type: 'datetime',
      labels: {
        style: {
          color: theme.palette.text.primary
        }
      }
    },
    yAxis: {
      title: {
        text: null
      },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: theme.palette.text.primary
        }
      ],
      labels: {
        style: {
          color: theme.palette.text.primary
        },
        formatter: function () {
          return ((this.value - 1) * 100).toFixed(1) + '%'
        }
      }
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
      formatter: function () {
        let points = ''
        this.points
          .sort((a, b) => {
            return b.point.y - a.point.y
          })
          .forEach((point) => {
            points += `<span style="color: ${
              point.series.color
            }">●</span> Retorno Portafolio: ${((point.y - 1) * 100).toFixed(
              2
            )}%<br>`
          })
        const date = new Date(this.x)
        const isoDate = date.toISOString()
        const dateOnly = isoDate.substring(0, isoDate.indexOf('T'))
        return (
          '<b style="font-size: 11px  ">' +
          format(parseISO(dateOnly), 'dd/MM/yyyy') +
          '</b>' +
          '<br>' +
          '<div style="margin-top: 20px">' +
          points +
          '</div>'
        )
      }
    },
    legend: {
      enabled: false
    },
    lang: {
      noData: ''
    },
    series: [
      {
        name: '',
        color: theme.palette.primary.main,
        data: [],
        turboThreshold: 0
      }
    ]
  })

  useEffect(() => {
    let mounted = true
    if (Object.keys(tickersWeightsDict).length > 0 && showInfoTable) {
      const fetchData = async () => {
        try {
          const result = await rebalanceReturnsRequest({
            startDate: format(startDate, 'yyyy-MM-dd'),
            endDate: format(endDate, 'yyyy-MM-dd'),
            currency: currency,
            tickersWeightsDict: tickersWeightsDict,
            rebalanceFrequency: rebalanceFrequency
          })
          const parsedData = parseReturnsData(result.data)
          setReturnsData(parsedData)
          // handleData(result.data)
          return result.data
        } catch (err) {
          errorNotification('miniBloombergError')
        }
      }

      if (mounted && currency) {
        window.addEventListener('transitionend', () => {
          Highcharts.charts.forEach((chart) => {
            if (chart) {
              chart.reflow()
            }
          })
        })
        setLoading(true)

        if (!Object.keys(tickersWeightsDict).length) {
          setLoading(false)
          return
        }
        fetchData().then((result) => {
          if (mounted && result) {
            const series = []
            const asset = result[0]
            const colorIndex = result.length - 1
            const data = asset.series.map((element) => {
              return [Date.parse(element[0]), element[1]]
            })

            series.push({
              ...asset,
              color: COLORS_GRAPH_ASSETS[colorIndex],
              data
            })
            setChartOptions({ ...chartOptions, series })
          }
          setLoading(false)
        })
      }
      return () => {
        mounted = false
        window.removeEventListener('transitionend', () => {
          Highcharts.charts.forEach((chart) => {
            if (chart) {
              chart.reflow()
            }
          })
        })
      }
    } else {
      setLoading(false)
      setReturnsData(null)
      // handleData(null)
    }
  }, [
    currency,
    startDate,
    endDate,
    tickersWeightsDict,
    rebalanceFrequency,
    theme.palette.secondary.main
  ])

  const parseReturnsData = (data) => {
    if (!data || data.length === 0) return null

    return {
      cagr: parseFloat(data[0].cagr),
      vol: parseFloat(data[0].vol),
      ...data[0].returns
    }
  }

  useEffect(() => {
    const assetSearchTickers = async () => {
      const result = await portfolioAssetsSearchRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency: currency,
        search_term: searchTerm
      })
      return result.data
    }

    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(() => {
      if (searchTerm.length > 0) {
        setLoadingSearch(true)
        assetSearchTickers().then((tickersFounded) => {
          setOptionsTickers(tickersFounded)
          setLoadingSearch(false)
        })
      } else {
        setLoadingSearch(false)
        setOptionsTickers([])
      }
      clearInterval(intervalRef.current)
    }, 500)
  }, [searchTerm, startDate, endDate, currency])

  useEffect(() => {
    setLoadingSavedList(true)
    const fetchList = async () => {
      try {
        const data = await fetchSavedList()
        setSavedList(data)
      } catch (err) {
        errorNotification('miniBloombergError')
      } finally {
        setLoadingSavedList(false)
      }
    }

    fetchList()
  }, [])

  const handleOnInputChange = async (e) => {
    setSearchTerm(e.target.value)
  }

  const fetchSavedList = async () => {
    try {
      const result = await rebalancePortfolioSearchRequest()
      return result.data
    } catch (err) {
      console.log(err)
    }
  }

  const deleteSavedData = async (uuid) => {
    try {
      const result = await deleteRebalancePortfolioRequest({
        uuid
      })
      fetchSavedList().then((data) => {
        setSavedList(data)
        setSavedListEdit({ uuid: null, text: '' })
      })
      return result.data
    } catch (err) {
      console.log(err)
    }
  }

  const upsertSavedData = async (params) => {
    try {
      let result
      if (params.uuid) {
        result = await updateRebalancePortfolioRequest({
          ...params
        })
      } else {
        result = await createRebalancePortfolioRequest({
          ...params
        })
      }
      fetchSavedList().then((data) => {
        setSavedList(data)
        if (params.uuid) {
          setSavedListEdit({ uuid: null, text: '' })
          if (params.replaceList) {
            cleanSavedData()
          }
        } else {
          cleanSavedData()
        }
      })
      return result.data
    } catch (err) {
      console.log(err)
    }
  }

  const cleanSavedData = async () => {
    setOpenEditDialog(false)
    setOpenSaveDialog(false)
    setSavedListEdit({ uuid: null, text: '' })
  }
  const optionsTickersDict = useMemo(() => {
    return optionsTickers.reduce((acc, ticker) => {
      acc[ticker.ticker] = ticker.name
      return acc
    }, {})
  }, [optionsTickers])

  const optionsTickersIds = useMemo(() => {
    return [...tickers, ...optionsTickers.map((ticker) => ticker.ticker)]
  }, [tickers, optionsTickers])

  const downloadHandler = async () => {
    setLoadingDownload(true)
    try {
      const response = await downloadRebalancePortfolioRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency: currency,
        tickersWeightsDict: tickersWeightsDict,
        tickersNamesDict: selectedTickersNamesDict,
        rebalanceFrequency: rebalanceFrequency
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Abaqus - Rebalance Series returns.xlsx')
      document.body.appendChild(link)
      link.click()
    } catch (err) {
      errorNotification('generalError')
    }
    setLoadingDownload(false)
  }

  const handleWeightChange = (ticker, value) => {
    setTickersWeightsDict((prevTickersWeightsDict) => ({
      ...prevTickersWeightsDict,
      [ticker]: parseFloat(value) || 0
    }))
    setWeightsError('')
  }

  const handleTickerSelection = (_tickers) => {
    const addedTickers = _tickers.filter((t) => !tickers.includes(t))
    const removedTickers = tickers.filter((t) => !_tickers.includes(t))

    setTickers(_tickers)

    setSelectedTickersNamesDict((prevDict) => {
      const newDict = { ...prevDict }

      addedTickers.forEach((ticker) => {
        newDict[ticker] = optionsTickersDict[ticker]
      })

      removedTickers.forEach((ticker) => {
        delete newDict[ticker]
      })

      return newDict
    })

    removedTickers.forEach((ticker) => handleTickerRemove(ticker))
  }

  const handleTickerRemove = (ticker) => {
    setTickers((prevTickers) => prevTickers.filter((t) => t !== ticker))
    setTickersWeightsDict((prevWeights) => {
      const rest = { ...prevWeights }
      delete rest[ticker]
      return rest
    })
  }

  const handleWeightsSubmit = () => {
    const totalWeights = Object.values(tickersWeightsDict).reduce(
      (sum, weight) => sum + weight,
      0
    )
    if (totalWeights > 100) {
      setWeightsError(
        `La suma de los pesos no puede ser mayor a 100, te estás excediendo por ${
          totalWeights - 100
        }`
      )
      setShowInfoTable(false)
      return
    } else if (totalWeights < 100) {
      setWeightsError(
        `La suma de los pesos no puede ser menor a 100, te faltan ${
          100 - totalWeights
        }`
      )
      setShowInfoTable(false)
      return
    }
    getRebalanceReturns()
  }

  const getRebalanceReturns = async () => {
    setLoading(true)
    setShowInfoTable(true)
    try {
      const result = await rebalanceReturnsRequest({
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        currency: currency,
        tickersWeightsDict: tickersWeightsDict,
        rebalanceFrequency: rebalanceFrequency
      })
      const parsedData = parseReturnsData(result.data)
      setReturnsData(parsedData)
      if (result.data) {
        const series = []
        const asset = result.data[0]
        const colorIndex = result.data.length - 1
        const data = asset.series.map((element) => {
          return [Date.parse(element[0]), element[1]]
        })

        series.push({
          ...asset,
          color: COLORS_GRAPH_ASSETS[colorIndex],
          data
        })

        setChartOptions((prevOptions) => ({ ...prevOptions, series }))
      }
    } catch (err) {
      errorNotification('miniBloombergError')
    } finally {
      setLoading(false)
      setOpenRebalanceDialog(false)
    }
  }

  const handleSavedPortfolioData = (savedData) => {
    setTickersWeightsDict(savedData.tickers_weights_dict)
    setRebalanceFrequency(savedData.rebalance_frequency)
    setSelectedTickersNamesDict(savedData.tickers_names_dict)
    setShowInfoTable(true)
    setTickers(Object.keys(savedData.tickers_weights_dict))
  }

  return (
    <CardWrapper
      title="Evolución rentabilidad de portafolios"
      titleFeedback="Evolución rentabilidad de portafolios"
    >
      <Grid
        container
        className={classes.assetsSelectorContainer}
        alignItems="center"
      >
        <Grid item xs={9}></Grid>
        <Grid item xs={1}>
          <Tooltip title="Simular Portafolio">
            <IconButton
              color="primary"
              onClick={() => {
                setOpenRebalanceDialog(true)
                setShowInfoTable(false)
              }}
            >
              <AddBoxIcon />{' '}
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={1}>
          <Tooltip
            title={Object.keys(tickersWeightsDict).length ? 'Guardar' : ''}
          >
            <IconButton
              disabled={!tickers.length}
              onClick={() => setOpenSaveDialog(true)}
              color="primary"
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={1}>
          <Tooltip
            title={Object.keys(tickersWeightsDict).length ? 'Descargar' : ''}
          >
            {loadingDownload ? (
              <CircularProgress />
            ) : (
              <IconButton
                disabled={!Object.keys(tickersWeightsDict).length}
                onClick={downloadHandler}
                color="primary"
              >
                <GetAppIcon />
              </IconButton>
            )}
          </Tooltip>
        </Grid>
      </Grid>
      {loading ? (
        <div style={{ height: 400 }}>
          <Loading />
        </div>
      ) : Object.keys(tickersWeightsDict).length > 0 && showInfoTable ? (
        <>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </>
      ) : (
        <Grid container style={{ height: 200 }} spacing={1}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            item
            xs={12}
            spacing={3}
          >
            <Typography variant="h6" component="h2">
              Por favor selecciona el portafolio a simular
            </Typography>
          </Grid>
        </Grid>
      )}
      {loadingSavedList ? (
        <Loading />
      ) : (
        savedList?.length > 0 && (
          <Grid container alignItems="center">
            {savedList?.map((savedListItem) => (
              <Chip
                key={savedListItem.uuid}
                style={{
                  marginRight: 12,
                  color: '#fff'
                }}
                size="small"
                label={savedListItem.name}
                color="primary"
                onClick={() => handleSavedPortfolioData(savedListItem)}
              />
            ))}
            <Tooltip title="Editar">
              <IconButton
                onClick={() => setOpenEditDialog(true)}
                color="primary"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )
      )}
      {Object.keys(tickersWeightsDict).length > 0 && showInfoTable && (
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper>
                <Box padding={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="subtitle1" align="left">
                        <b>Activo</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="subtitle1" align="right">
                        <b>Peso</b>
                      </Typography>
                    </Grid>
                  </Grid>

                  {Object.keys(tickersWeightsDict).map((ticker, index) => (
                    <Grid
                      container
                      spacing={2}
                      key={ticker + index}
                      style={{ marginTop: '0.5em' }}
                      alignItems="center"
                    >
                      <Grid item xs={3}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                        >
                          <Typography variant="body1">
                            <b>{ticker}</b>
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {selectedTickersNamesDict[ticker] || 'N/A'}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={9}>
                        <Box display="flex" alignItems="center" width="100%">
                          <Box flexGrow={1} mr={1}>
                            <Box
                              bgcolor={
                                COLORS_GRAPH_ASSETS[
                                  index % COLORS_GRAPH_ASSETS.length
                                ] || 'grey'
                              }
                              height={8}
                              borderRadius={4}
                              style={{
                                width: `${tickersWeightsDict[ticker] || 0}%`,
                                transition: 'width 0.3s ease'
                              }}
                            />
                          </Box>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            style={{ minWidth: '40px', textAlign: 'right' }}
                          >
                            <b>{tickersWeightsDict[ticker] || 0}%</b>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Paper>

              <Box padding={2}>
                <Typography variant="subtitle1">
                  <b>Frecuencia de Rebalanceo:</b> {rebalanceFrequency || 'N/A'}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box mt={2}>
                <ReturnsTable returnsData={returnsData} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={openRebalanceDialog}
        onClose={() => setOpenRebalanceDialog(false)}
      >
        <DialogTitle>Selecciona Activos</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Autocomplete
              loading={loadingSearch}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              multiple
              getOptionLabel={(option) =>
                `${optionsTickersDict[option]} (${option})`
              }
              filterSelectedOptions
              getOptionSelected={(option, value) => value === option}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    <Tooltip title={option} key={index}>
                      <Chip
                        style={{
                          marginRight: 12,
                          backgroundColor: COLORS_GRAPH_ASSETS[0],
                          color: getTextColor(COLORS_GRAPH_ASSETS[0])
                        }}
                        size="small"
                        label={option}
                        key={option}
                        classes={{ deleteIcon: classes.deleteIcon }}
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  )
                })
              }
              loadingText={
                loadingSearch ? 'Buscando...' : 'No se encontraron resultados'
              }
              onBlur={() => setOptionsTickers([])}
              options={loadingSearch ? [] : optionsTickersIds}
              onInputChange={(e) => handleOnInputChange(e)}
              value={tickers}
              size="small"
              onChange={(_, _tickers) => {
                handleTickerSelection(_tickers)
              }}
              className={classes.assetsSelector}
              noOptionsText="No se encontraron resultados"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecciona uno o más activos:"
                  variant="outlined"
                  placeholder="Activos"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                />
              )}
            />
          </Box>

          <Box mb={2}>
            <Grid container spacing={2}>
              {tickers.map((ticker) => (
                <Grid
                  item
                  container
                  key={ticker}
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                    >
                      <Chip
                        label={ticker}
                        style={{
                          backgroundColor:
                            COLORS_GRAPH_ASSETS[0] || COLORS_GRAPH_ASSETS[0],
                          color: getTextColor(
                            COLORS_GRAPH_ASSETS[0] || COLORS_GRAPH_ASSETS[0]
                          )
                        }}
                      />
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{ display: 'block', textAlign: 'left' }}
                      >
                        {selectedTickersNamesDict[ticker] || 'N/A'}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Peso (%)"
                      variant="outlined"
                      size="small"
                      type="number"
                      inputProps={{
                        min: 0,
                        max: 100,
                        step: 1,
                        style: { textAlign: 'right' }
                      }}
                      style={{ width: 80 }}
                      value={tickersWeightsDict[ticker] || ''}
                      onChange={(e) =>
                        handleWeightChange(ticker, e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Box>

          {weightsError && (
            <Typography color="error">{weightsError}</Typography>
          )}

          <Box mt={2}>
            <Autocomplete
              options={[
                'No Rebalance',
                'Weekly',
                'Monthly',
                'Quarterly',
                'Annually'
              ]}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Frecuencia de rebalanceo"
                  variant="outlined"
                  placeholder="Selecciona una opción"
                />
              )}
              value={rebalanceFrequency}
              onChange={(event, value) => setRebalanceFrequency(value)}
              style={{ marginTop: 16 }}
            />
          </Box>

          <Box mt={2} display="flex" justifyContent="space-between">
            <Button
              onClick={() => {
                handleWeightsSubmit()
              }}
              color="primary"
            >
              Enviar
            </Button>
            <Button
              onClick={() => {
                setOpenRebalanceDialog(false)
                setWeightsError('')
              }}
              color="primary"
            >
              Cerrar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={openSaveDialog}
        onClose={() => setOpenSaveDialog(false)}
      >
        <DialogTitle>Guardar lista de activos</DialogTitle>
        <DialogContent>
          <List>
            {savedList?.map((savedListItem) => (
              <ListItem key={savedListItem.uuid}>
                <ListItemText primary={savedListItem.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="save"
                    onClick={() =>
                      upsertSavedData({
                        uuid: savedListItem.uuid,
                        name: savedListItem.name,
                        tickers_weights_dict:
                          savedListItem.tickers_weights_dict,
                        rebalance_frequency: savedListItem.rebalance_frequency,
                        tickers_names_dict: savedListItem.tickers_names_dict,
                        replaceList: true
                      })
                    }
                  >
                    <SaveIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <Divider />
            <ListItem>
              <ListItemText
                primary={
                  <TextField
                    label="Nueva lista"
                    size="small"
                    value={savedListEdit.text}
                    onChange={(e) =>
                      setSavedListEdit({ uuid: null, text: e.target.value })
                    }
                    placeholder="Nueva lista"
                    margin="normal"
                    fullWidth
                  />
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="save"
                  onClick={() => {
                    upsertSavedData({
                      name: savedListEdit.text,
                      tickers_weights_dict: tickersWeightsDict,
                      rebalance_frequency: rebalanceFrequency,
                      tickers_names_dict: selectedTickersNamesDict
                    })
                  }}
                >
                  <SaveIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      >
        <DialogTitle>Editar listas guardadas</DialogTitle>
        <DialogContent>
          <List>
            {savedList?.map((savedListItem) => (
              <ListItem key={savedListItem.uuid}>
                {savedListEdit.uuid === savedListItem.uuid ? (
                  <TextField
                    value={savedListEdit.text}
                    onChange={(e) =>
                      setSavedListEdit({
                        uuid: savedListItem.uuid,
                        text: e.target.value
                      })
                    }
                  />
                ) : (
                  <ListItemText primary={savedListItem.name} />
                )}
                <ListItemSecondaryAction>
                  {savedListEdit.text &&
                  savedListItem.uuid === savedListEdit.uuid ? (
                    <IconButton
                      edge="end"
                      onClick={() =>
                        upsertSavedData({
                          uuid: savedListItem.uuid,
                          name: savedListEdit.text,
                          tickers_weights_dict:
                            savedListItem.tickers_weights_dict,
                          rebalance_frequency:
                            savedListItem.rebalance_frequency,
                          tickers_names_dict: savedListItem.tickers_names_dict
                        })
                      }
                    >
                      <SaveIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      edge="end"
                      onClick={() =>
                        setSavedListEdit({
                          uuid: savedListItem.uuid,
                          text: savedListItem.name
                        })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                  <IconButton
                    edge="end"
                    aria-label="save"
                    onClick={() => deleteSavedData(savedListItem.uuid)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </CardWrapper>
  )
}
