import OperationsRebalanceAssetsSeriesReturns from 'components/Graphs/OperationsRebalanceAssetsSeriesReturns'
import Auxiliary from 'utils/Auxiliary/Auxiliary'

export default function OperationsAssetsComparator() {
  return (
    <Auxiliary>
      <OperationsRebalanceAssetsSeriesReturns />
    </Auxiliary>
  )
}
